import { FuseSidebarService } from './../../../../@fuse/components/sidebar/sidebar.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Observable } from 'rxjs';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { RootStoreState } from 'app/root-store';
import { ActionName } from '@bsuccess/models/action-permission.model';
import { locale as english } from '../../../i18n/layout/components/projects-panel/en';
import { locale as frensh } from '../../../i18n/layout/components/projects-panel/fr';
import { locale as arabic } from '../../../i18n/layout/components/projects-panel/ar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { StudioProjectsStoreActions, StudioProjectsStoreSelectors } from 'app/root-store/studio-store/projects-store';
import { StudioProjectStoreActions, StudioProjectStoreSelectors } from 'app/root-store/studio-store/project-store';
import { ProjectModel } from '@bsuccess/models/project/project.model';
import { AccessStoreActions, AccessStoreSelectors } from 'app/root-store/access-store';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface ProjectNode {
    _id: string;
    name?: string;
    role?: string;
    subProjects?: ProjectNode[]
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    _id: string;
    subProjects?: ProjectNode[];
}

@Component({
    selector: 'projects-panel',
    templateUrl: './projects-panel.component.html',
    styleUrls: ['./projects-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProjectsPanelComponent implements OnInit {
    projectsTree$: Observable<any[]>;
    currentProject$: Observable<ProjectModel>;
    currentProject: ProjectModel;
    ActionName = ActionName;
    isUserCndpAllowed: boolean;
    currentUrl: string = window.location.href;
    userInfo$: Observable<any>;
    userInfo: any;
    
    private _transformer = (node: ProjectNode, level: number) => {
        return {
            expandable: !!node.subProjects && node.subProjects.length > 0,
            name: node.name,
            role: node.role,
            level: level,
            _id: node._id
        };
    }

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.subProjects);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(
        private _store: Store<RootStoreState.State>,
        private translationLoaderService: FuseTranslationLoaderService,
        public translateService: TranslateService,
        private _fuseSidebarService: FuseSidebarService
    ) {
        this.translationLoaderService.loadTranslations(english, frensh, arabic);
        // get current project
        this.currentProject$ = this._store.pipe(
            select(StudioProjectStoreSelectors.selectCurrent)
        );

        this.userInfo$ = this._store.pipe(
            select(AccessStoreSelectors.selectUserInfo)
        );

        this.userInfo$.subscribe(_ => { 
            this.userInfo = _ ;
            if (this.currentUrl.includes('cndp')) { 
                this.isUserCndpAllowed = _?.isAdmin ? true : false;
            }        
        });
    }

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    ngOnInit(
    ): void {
        this._store.dispatch(StudioProjectsStoreActions.loadProjectsTree());
        this.projectsTree$ = this._store.pipe(
            select(StudioProjectsStoreSelectors.selectProjectsTree)
        );

        this.currentProject$.subscribe(project => {
            this.currentProject = project;
            if (this.dataSource.data && this.currentProject) {
                if (this.currentProject._id) {
                    this.treeControl.collapseAll();
                    this.expand(this.dataSource.data, this.currentProject._id);
                }
            }
        });

        this.projectsTree$.subscribe(projects => {
            this.dataSource.data = projects;
            if (projects && this.currentProject) {
                if (this.currentProject._id) {
                    this.treeControl.collapseAll();
                    this.expand(this.dataSource.data, this.currentProject._id);
                }
            }
        });
    }

    toggleProjectsOpened(): void {
        this._fuseSidebarService.getSidebar('projectsPanel').toggleOpen();
    }

    showSubProjectDialog(parent): void {
        this._store.dispatch(StudioProjectStoreActions.showSubProjectDialog({
            parent
        }));
    }

    expand(data: any[], _id: string): any {
        data.forEach((node, index) => {
            if (node.subProjects && node.subProjects.find(c => c._id === _id)) {
                const indexOfNode = this.treeControl.dataNodes.findIndex(x => x._id === node._id);
                this.treeControl.expand(this.treeControl.dataNodes[indexOfNode]);
                this.expand(this.dataSource.data, node._id);
              }
            else if (node.subProjects && node.subProjects.find(c => c.subProjects)) {
                this.expand(node.subProjects, _id);
            }
        });
    }

    newProject(): void {
        this._store.dispatch(AccessStoreActions.openNewProjectDialog());
    }
}
